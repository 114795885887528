import React from "react";
import { Photo } from "@repo/ui/icons";

const SidebarSecondHeader = ({ images }: { images?: any[] }) => {
  return (
    <div className="mt-4 flex items-end justify-between border-b-[1px] pb-2">
      <div className="flex items-center gap-2">
        <Photo />
        <span className="font-semibold">{images?.length} Searched Images</span>
      </div>
    </div>
  );
};

export default SidebarSecondHeader;
