import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface SentinelOptionState {
  selectedFirstFrame: string
  selectedTrack: string
}

type SentinelOptionActions = {
  setSelectedFirstFrame: (firstFrame: string) => void
  setSelectedTrack: (track: string) => void
}

const useSentinelOption = create<SentinelOptionState & SentinelOptionActions>()(
  persist(
    (set) => ({
      selectedFirstFrame: '',
      selectedTrack: '',
      setSelectedFirstFrame: (firstFrame: string) =>
        set({ selectedFirstFrame: firstFrame }),
      setSelectedTrack: (track: string) => set({ selectedTrack: track }),
    }),
    { name: 'sentinel-option' }
  )
)

export default useSentinelOption
