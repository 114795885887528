import { ReadonlyURLSearchParams } from 'next/navigation'

import { getInjection } from '@/di/container'

export async function getSentinelUseCase(data: ReadonlyURLSearchParams) {
  try {
    const sentinelService = getInjection('ISentinelService')

    const queries = sentinelService.generateSentinelQueries(data)

    return await sentinelService.getSentinel(queries)
  } catch (error) {
    throw error
  }
}
