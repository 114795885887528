import { useGetSentinel } from '../(home)/searched-data/(strategy)/hooks/useGetSentinel'
import { satelliteService } from '@repo/common/services'
import * as L from 'leaflet'
import { useSearchParams } from 'next/navigation'
import { useCallback, useMemo, useState } from 'react'

import { detailedDataCardService } from '@/app/(main)/(home)/searched-data/(strategy)/components/RelatedTemporalData/service/detailed-card.service'

// import {
//   FilteredSentinelData,
//   SentinelData,
//   SentinelDataGroup,
// } from '@/common/interfaces/data.interface'
import useMapStore from '@/common/store/map'
import useSentinelOption from '@/common/store/sentinel-option'
import useSidebarStore from '@/common/store/sidebar'
import { Sentinel } from '@/core/entities/models/sentinel'

const useSentinelImages = () => {
  const searchParams = useSearchParams()
  const [isAllImagesLoaded, setIsAllImagesLoaded] = useState(false)
  const { data } = useGetSentinel(searchParams)

  const { shouldOpenProgressBar, setShouldOpenProgressBar } = useSidebarStore()
  const { addImageOverlay, removeImageOverlay } = useMapStore()

  const { selectedFirstFrame, selectedTrack } = useSentinelOption()

  const removeOldImages = useCallback(() => {
    if (selectedFirstFrame && selectedTrack && data) {
      const groupedData = satelliteService.groupedData(data)

      if (groupedData) {
        Object.values(groupedData).forEach((dataGroup) => {
          if (dataGroup.grdData && dataGroup.grdData.length > 0) {
            dataGroup.grdData.forEach((grdItem) => {
              removeImageOverlay(grdItem)
            })
          }
        })
      }
    }
  }, [data, removeImageOverlay, selectedFirstFrame, selectedTrack])

  const handleLoadAllImages = useCallback(async () => {
    // console.log("handleLoadAllImages started");
    if (selectedFirstFrame && selectedTrack && data) {
      const groupedData = satelliteService.groupedData(data)

      if (groupedData) {
        const promises: Promise<void>[] = []

        Object.values(groupedData).forEach((dataGroup) => {
          if (dataGroup.grdData && dataGroup.grdData.length > 0) {
            dataGroup.grdData.forEach((grdItem) => {
              if (
                grdItem.firstFrame === +selectedFirstFrame &&
                grdItem.track === +selectedTrack
              ) {
                const imageBounds =
                  detailedDataCardService.calculateImageBounds(
                    grdItem,
                    L.latLngBounds,
                    L.latLng
                  )
                const addedData = {
                  ...grdItem,
                  imageBounds,
                }
                // console.log(
                //   `Adding image overlay for ${addedData.firstFrame}-${addedData.track}-${addedData.productName}`
                // )
                promises.push(addImageOverlay(addedData))
              }
            })
          }
        })

        try {
          // console.log(`Waiting for ${promises.length} image overlays to load`);
          await Promise.all(promises)
          // console.log("All images loaded successfully");
          setIsAllImagesLoaded(true)
          setShouldOpenProgressBar(true)
          const currentMap = useMapStore.getState().currentMap

          if (currentMap) {
            // console.log("Invalidating map size");
            currentMap.invalidateSize()
          } else {
            console.error('Current map is not available')
          }
        } catch (error) {
          console.error('Error loading images:', error)
        }
      } /* else {
        console.log("No grouped data available");
      } */
    } else {
      // console.log('firstFrame or track is missing', {
      //   selectedFirstFrame,
      //   selectedTrack,
      // })
    }
  }, [data, selectedFirstFrame, selectedTrack, addImageOverlay])

  const handleCloseProgressBar = () => {
    removeOldImages()
    setShouldOpenProgressBar(false)
  }

  const filteredGroupedData = useMemo(() => {
    if (!data) {
      return {}
    }

    // console.log(data)

    const groupedData: Record<
      string,
      { grdData: Sentinel[]; nonGrdData: Sentinel[] }
    > = satelliteService.groupedData(data)
    return (
      Object.entries(groupedData) as [
        string,
        { grdData: Sentinel[]; nonGrdData: Sentinel[] },
      ][]
    ).reduce(
      (
        acc: Record<string, { grdData: Sentinel[]; nonGrdData: Sentinel[] }>,
        [date, dataGroup]
      ) => {
        if (
          dataGroup.grdData[0].firstFrame === +selectedFirstFrame &&
          dataGroup.grdData[0].track === +selectedTrack
        ) {
          acc[date] = dataGroup
        }
        return acc
      },
      {}
    )
  }, [data, selectedFirstFrame, selectedTrack])

  return {
    isAllImagesLoaded,
    shouldOpenProgressBar,
    removeOldImages,
    handleLoadAllImages,
    handleCloseProgressBar,
    setShouldOpenProgressBar,
    filteredGroupedData,
  }
}

export default useSentinelImages
