import useProcessStore from "@/common/store/process";

export class DetailedDataCardPersistance {
  async toggleImageAndOverlay(data: any, toggleImageOverlay: any) {
    return toggleImageOverlay(data);
  }

  getDynamicTagObjects(data, tags) {
    return tags
      .filter((tag) => data[tag] !== undefined) // 정의되지 않은 태그는 제외
      .map((tag) => ({ [tag]: data[tag] })); // 각 태그를 {태그명: 값} 객체로 변환
  }

  getProcessName() {
    return useProcessStore.getState().processName;
  }

  getDynamicTags(data, tags) {
    return tags
      .filter((tag) => tag !== "platform")
      .map((tag) => data[tag])
      .filter((tag) => tag !== undefined);
  }
}
