import { Container } from 'inversify'
import 'reflect-metadata'

import { NotificationModule } from './modules/notification.module'
import { SentinelModule } from './modules/sentinel.module'
import { DI_RETURN_TYPES, DI_SYMBOLS } from './types'

export const ApplicationContainer = new Container({
  defaultScope: 'Singleton',
})

export const initializeContainer = () => {
  ApplicationContainer.load(SentinelModule)
  ApplicationContainer.load(NotificationModule)
}

if (process.env.NODE_ENV !== 'test') {
  initializeContainer()
}

export function getInjection<K extends keyof typeof DI_SYMBOLS>(
  symbol: K
): DI_RETURN_TYPES[K] {
  return ApplicationContainer.get(DI_SYMBOLS[symbol])
}
