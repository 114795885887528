import { ISentinelService } from "@/core/application/services/sentinel.services.interface";
import { inject, injectable } from "inversify";
import { DI_SYMBOLS } from "@/di/types";
import { type ISentinelRepository } from "@/core/application/repositories/sentinel.repository.interface";
import { ReadonlyURLSearchParams } from "next/navigation"; 
import {
  getSentinelSchema,
  Sentinel,
  SentinelQueries,
} from "@/core/entities/models/sentinel";
import { ApiResponse } from '@/common/interfaces/api-response.interface'
  
@injectable()
export class SentinelService implements ISentinelService {
  constructor(
    @inject(DI_SYMBOLS.ISentinelRepository)
    private readonly _sentinelRepository: ISentinelRepository
  ) {}

  generateSentinelQueries(
    searchParams: ReadonlyURLSearchParams
  ): SentinelQueries {
    // console.log(Object.fromEntries(searchParams))
    const parsedQueries = this.parseSearchParams(searchParams);
    // console.log('parsedQueries', parsedQueries)
    return getSentinelSchema.parse(parsedQueries);
  }

  async getSentinel(
    queries: SentinelQueries
  ): Promise<ApiResponse<Sentinel[]>> {
    return this._sentinelRepository.getSentinel(queries);
  }

  private parseSearchParams(
    searchParams: ReadonlyURLSearchParams
  ): Record<string, unknown> {
    const result: Record<string, unknown> = {};
    for (const [key, value] of searchParams.entries()) {
      if (key in getSentinelSchema.shape) {
        // console.log(key, value)
        result[key] = this.parseValue(key, value);
      }
    }
    return result;
  }

  private parseValue(key: string, value: string): unknown {
    switch (key) {
      case "upperLeftLat":
      case "upperLeftLng":
      case "lowerRightLat":
      case "lowerRightLng":
      case "page":
      case "pageSize":
        return Number(value);
      case "polarization":
        return value.replace(/\s+/g, '');
      case "beamMode":
      case "flightDirection":
      case "startDate":
      case "endDate":
      default:
        return value;
    }
  }
}
