import { z } from "zod";

export enum SentinelBeamMode {
  SM = "SM",
  IW = "IW",
  EW = "EW",
}

export enum SentinelPolarization {
  VV = "VV",
  VV_VH = "VV+VH",
  HH = "HH",
  HH_HV = "HH+HV",
}

export enum SentinelFlightDirection {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export type Sentinel = {
  platform: "Sentinel-1A";
  sensor: "C-SAR";
  product_file_id: string;
  absoluteBurstID: null;
  granuleName: string;
  absoluteOrbit: number;
  beamMode: SentinelBeamMode;
  centerLat: number;
  centerLon: number;
  configurationName: string;
  downloadUrl: string;
  farEndLat: number;
  farEndLon: number;
  farStartLat: number;
  farStartLon: number;
  flightDirection: SentinelFlightDirection;
  polarization: SentinelPolarization;
  processingDate: string;
  processingDescription: string;
  processingLevel: string;
  processingType: string;
  processingTypeDisplay: string;
  sceneDate: string;
  sizeMB: number;
  startTime: string;
  stopTime: string;
  stringFootprint: string[];
  beamModeType: SentinelBeamMode;
  doppler: number;
  finalFrame: number;
  firstFrame: number;
  frameNumber: number;
  granuleType: string;
  groupID: string;
  lookDirection: string;
  md5sum: string;
  nearEndLat: number;
  nearEndLon: number;
  nearStartLat: number;
  nearStartLon: number;
  productName: string;
  relativeOrbit: number;
  sceneId: string;
  track: number;
  beamSwath: null;
  browse: string;
  catSceneId: null;
  collectionName: null;
  faradayRotation: null;
  fileName: string;
  flightLine: null;
  formatName: null;
  frequency: null;
  incidenceAngle: null;
  insarGrouping: null;
  insarStackSize: null;
  masterGranule: null;
  missionName: null;
  offNadirAngle: null;
  percentCoherence: null;
  percentTroposphere: null;
  percentUnwrapped: null;
  pointingAngle: null;
  sarSceneId: null;
  sceneDateString: null;
  slaveGranule: null;
  status: null;
  thumbnailUrl: null;
  varianceTroposphere: null;
};

export const getSentinelSchema = z.object({
  upperLeftLat: z.number({
    required_error: "Upper left latitude is required",
  }),
  upperLeftLng: z.number({
    required_error: "Upper left longitude is required",
  }),
  lowerRightLat: z.number({
    required_error: "Lower right latitude is required",
  }),
  lowerRightLng: z.number({
    required_error: "Lower right longitude is required",
  }),
  category: z.string().optional(),
  page: z
    .number({
      required_error: "Page is required",
    })
    .default(1),
  pageSize: z
    .number({
      required_error: "Page size is required",
    })
    .default(10),
  beamMode: z.nativeEnum(SentinelBeamMode).default(SentinelBeamMode.IW),
  polarization: z
    .nativeEnum(SentinelPolarization)
    .default(SentinelPolarization.VV_VH),
  flightDirection: z
    .nativeEnum(SentinelFlightDirection)
    .default(SentinelFlightDirection.ASCENDING),
  startDate: z
    .string({
      required_error: "Start date is required",
    })
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Date must be in YYYY-MM-DD format")
    .default(() => new Date().toISOString().split("T")[0]),
  endDate: z
    .string({
      required_error: "End date is required",
    })
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Date must be in YYYY-MM-DD format")
    .default(() => new Date().toISOString().split("T")[0]),
});

export type SentinelQueries = z.infer<typeof getSentinelSchema>;
