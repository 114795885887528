import { ReadonlyURLSearchParams } from 'next/navigation'

// import { SentinelData } from '@/common/interfaces/data.interface'
import { getSentinelUseCase } from '@/core/application/use-cases/sentinel/get-sentinel.use-case'
import { Sentinel } from '@/core/entities/models/sentinel'

// import { SentinelQueries } from '@/core/entities/models/sentinel'

function presenter(data: Sentinel[]) {
  return data
}

export async function getSentinelController(queries: ReadonlyURLSearchParams) {
  try {
    const sentinel = await getSentinelUseCase(queries)
    return presenter(sentinel.data)
  } catch (error) {
    throw error
  }
}
