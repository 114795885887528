import { ApiResponse } from '@/common/interfaces/api-response.interface'
// import { SentinelData } from '@/common/interfaces/data.interface'
import { ISentinelRepository } from '@/core/application/repositories/sentinel.repository.interface'
import { Sentinel, SentinelQueries } from '@/core/entities/models/sentinel'
import { injectable } from 'inversify'
 
@injectable()
export class SentinelRepository implements ISentinelRepository {
  private readonly baseUrl = `${process.env.NEXT_PUBLIC_MAIN_SERVER_API_URL}/api/satellites/search/sentinel`
  async getSentinel(
    queries: SentinelQueries
  ): Promise<ApiResponse<Sentinel[]>> {
    const params = new URLSearchParams({
      upperLeftLat: queries.upperLeftLat.toString(),
      upperLeftLng: queries.upperLeftLng.toString(),
      lowerRightLat: queries.lowerRightLat.toString(),
      lowerRightLng: queries.lowerRightLng.toString(),
      page: queries.page.toString(),
      pageSize: queries.pageSize.toString(),
      beamMode: queries.beamMode,
      polarization: queries.polarization,
      flightDirection: queries.flightDirection,
      startDate: queries.startDate,
      endDate: queries.endDate,
    });

    const response = await fetch(`${this.baseUrl}?${params.toString()}`);
    return await response.json()
  }
}
