import Image from "next/image";

import LOGO from "~/assets/favicon.png";

const ImageNotFound = ({ width = 40, height = 40, fontSize = 7 }) => {
  return (
    <div className="flex size-full flex-col items-center justify-center font-bold">
      <Image src={LOGO} width={width} height={height} alt="Image not found" />
      <span
        style={{
          fontSize: `${fontSize}px`,
          textAlign: "center",
          color: "black",
        }}
        className="font-bold hover:text-black"
      >
        Image <br />
        Not Found
      </span>
    </div>
  );
};

export default ImageNotFound;
