import { DI_SYMBOLS } from '../types'
import { ContainerModule, interfaces } from 'inversify'

import { ISentinelRepository } from '@/core/application/repositories/sentinel.repository.interface'
import { ISentinelService } from '@/core/application/services/sentinel.services.interface'
import { SentinelRepository } from '@/core/infrastructure/repositories/sentinel.repository'
import { SentinelService } from '@/core/infrastructure/services/sentinel.service'

const initializeModule = (bind: interfaces.Bind) => {
  bind<ISentinelRepository>(DI_SYMBOLS.ISentinelRepository).to(
    SentinelRepository
  )
  bind<ISentinelService>(DI_SYMBOLS.ISentinelService).to(SentinelService)
}

export const SentinelModule = new ContainerModule(initializeModule)
