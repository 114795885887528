"use client";

import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const RectangleSkeleton = ({ rows = 4, height = 70 }) => {
  const [items, setItems] = useState([{ id: Date.now(), height }]); // 초기 아이템 설정

  useEffect(() => {
    const interval = setInterval(() => {
      setItems((currentItems) => {
        const newItems = [...currentItems, { id: Date.now(), height }];

        // 배열의 길이가 rows보다 크면 첫 번째 아이템 제거
        if (newItems.length > rows) {
          newItems.shift(); // 배열의 첫 번째 요소 제거
        }

        return newItems;
      });
    }, 1000); // 1초마다 아이템 추가

    return () => clearInterval(interval);
  }, [rows, height]);

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="flex w-full max-w-4xl flex-col gap-2">
        <AnimatePresence>
          {items.map((item) => (
            <motion.div
              key={item.id}
              layout
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{
                opacity: 0,
                y: 20,
                transition: { duration: 0.5 },
              }}
              style={{ height: `${item.height}px` }}
              className="mb-2 grid grid-cols-12 gap-4 rounded-lg bg-gray-300"
            />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default RectangleSkeleton;
