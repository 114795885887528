import { ApiResponse } from "@/common/interfaces/api-response.interface";
import { INotificationRepository } from "@/core/application/repositories/notification.repository.interface";
import { Notification } from "@/core/entities/models/notification";
import fetchAPI from "@repo/common/helpers/fetch";
import { injectable } from 'inversify'

@injectable()
export class NotificationRepository implements INotificationRepository {
  private _baseUrl = `${process.env.NEXT_PUBLIC_MAIN_SERVER_API_URL}/api/satellites/subscribe/user`;

  async getNotifications(userId: string): Promise<ApiResponse<Notification[]>> {
    const response = await fetch(`${this._baseUrl}?user_id=${userId}`)
    return await response.json()
  }

  async addSubscription(
    userId: string,
    satelliteId: string,
  ): Promise<ApiResponse<Notification[]>> {
    return await fetchAPI<ApiResponse<Notification[]>>(
      `${this._baseUrl}?user_id=${userId}`,
      {
        method: "POST",
        body: JSON.stringify({ satellite_id: satelliteId }),
      },
    );
  }

  async unsubscribeToLatestData(
    userId: string,
    satelliteId: string,
  ): Promise<ApiResponse<Notification[]>> {
    return await fetchAPI<ApiResponse<Notification[]>>(
      `${this._baseUrl}?user_id=${userId}`,
      {
        method: "DELETE",
        body: JSON.stringify({ satellite_id: satelliteId }),
      },
    );
  }

  async subscribeToLatestData(
    userId: string,
    satelliteId: string,
  ): Promise<ApiResponse<Notification[]>> {
    return await fetchAPI<ApiResponse<Notification[]>>(
      `${this._baseUrl}?user_id=${userId}`,
      {
        method: "POST",
        body: JSON.stringify({ satellite_id: satelliteId }),
      },
    );
  }
}
