import { INotificationRepository } from '@/core/application/repositories/notification.repository.interface'
import { ISentinelRepository } from '@/core/application/repositories/sentinel.repository.interface'
import { ISentinelService } from '@/core/application/services/sentinel.services.interface'

export const DI_SYMBOLS = {
  // Repositories
  ISentinelRepository: Symbol('ISentinelRepository'),
  INotificationRepository: Symbol('INotificationRepository'),
  // Services
  ISentinelService: Symbol('ISentinelService'),
}

export interface DI_RETURN_TYPES {
  // Repositories
  ISentinelRepository: ISentinelRepository
  INotificationRepository: INotificationRepository
  // Services
  ISentinelService: ISentinelService
}
