import { DI_SYMBOLS } from '../types'
import { ContainerModule, interfaces } from 'inversify'

import { INotificationRepository } from '@/core/application/repositories/notification.repository.interface'
import { NotificationRepository } from '@/core/infrastructure/repositories/notification.repository'

const initializeModule = (bind: interfaces.Bind) => {
  bind<INotificationRepository>(DI_SYMBOLS.INotificationRepository).to(
    NotificationRepository
  )
}

export const NotificationModule = new ContainerModule(initializeModule)
