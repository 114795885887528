import { IDetailedCardService } from '../interface/detailed-card.params'
import { DetailedDataCardPersistance } from '../repository/DetailedDataCard.repository'

export class DetailedCardService implements IDetailedCardService {
  persistance: DetailedDataCardPersistance

  constructor() {
    this.persistance = new DetailedDataCardPersistance()
  }

  calculateImageBounds(data, latLngBounds, latLng) {
    const {
      farStartLat,
      nearStartLat,
      farEndLat,
      nearEndLat,
      nearStartLon,
      nearEndLon,
      farStartLon,
      farEndLon,
    } = data
    const minLat = Math.min(farStartLat, nearStartLat, farEndLat, nearEndLat)
    const maxLat = Math.max(farEndLat, nearEndLat, farStartLat, nearStartLat)
    const minLon = Math.min(nearStartLon, nearEndLon, farStartLon, farEndLon)
    const maxLon = Math.max(farStartLon, farEndLon, nearStartLon, nearEndLon)

    return latLngBounds(latLng(minLat, minLon), latLng(maxLat, maxLon))
  }

  handleImageOverlay = async (
    data,
    setIsLoading,
    tags,
    toggleImageOverlay,
    latLngBounds,
    latLng
  ) => {
    const { toggleImageAndOverlay } = this.persistance
    const {
      frameNumber,
      productName,
      browse,
      stringFootprint,
      firstFrame,
      track,
      downloadUrl,
      thumbnailUrl,
    } = data
    const dynamicTagObjects = this.getDynamicTagObjects(data, tags)

    setIsLoading(true)

    try {
      const res = await toggleImageAndOverlay(
        {
          frameNumber,
          productName,
          browse: browse || '',
          polygon: stringFootprint || [],
          tags: dynamicTagObjects,
          processName: this.getProcessName(),
          imageBounds: this.calculateImageBounds(data, latLngBounds, latLng),
          firstFrame,
          track,
          groupID: data.groupID,
          downloadUrl,
          thumbnailUrl,
        },
        toggleImageOverlay
      )

      console.log('Image overlay toggled:', res)
    } catch (error) {
      console.error('Error loading image overlay:', error)
    } finally {
      setIsLoading(false)
    }
  }

  getDynamicTagObjects(data, tags) {
    return this.persistance.getDynamicTagObjects(data, tags)
  }

  getProcessName() {
    return this.persistance.getProcessName()
  }

  getDynamicTags(data, tags) {
    return this.persistance.getDynamicTags(data, tags)
  }
}

export const detailedDataCardService = new DetailedCardService()
