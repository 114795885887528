import { useSuspenseQuery } from '@tanstack/react-query'
import { ReadonlyURLSearchParams } from 'next/navigation'
import { z } from 'zod'

import { getSentinelController } from '@/core/interface-adapters/controller/sentinel/get-sentinel.controller'

export const useGetSentinel = (queries: ReadonlyURLSearchParams) => {
  return useSuspenseQuery({
    queryKey: ['sentinel', Object.fromEntries(queries)],
    queryFn: async () => {
      try {
        const result = await getSentinelController(queries)
        return result
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.error('Zod validation error:', error.errors)
          return null
        }
        throw error
      }
    },
    retry: (failureCount, error) => {
      if (error instanceof z.ZodError) {
        return failureCount < 3
      }
      return false
    },
    retryDelay: 1000,
  })
}
